import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero"
import PainPoint from "../components/pain-point"
import WhatYouLearn from "../components/what-you-learn"

const IndexPage = () => (
  <Layout>
    <SEO title="Make Money With Code" />
    <HeroSection />
    <PainPoint />
    <WhatYouLearn />
  </Layout>
)

export default IndexPage
