import React, { useState, useEffect } from "react"
import useForm from "react-hook-form"
import addToMailchimp from "gatsby-plugin-mailchimp"


const EmailForm = () => {
  const { register, handleSubmit, errors, formState, getValues } = useForm()
  const [mailchimpResult, setMailchimpResult] = useState({})
  const [formDisabled, setFormDisabled] = useState(false)

  async function onSubmit(data) { 
    const response = await addToMailchimp(data.EMAIL, { NAME: data.NAME })
    setMailchimpResult({...response, msg: fixMailchimpMessage(response.msg)})

    // only want to temporarily display error
    if (response.result === "error") {
      setTimeout(() => {
        setFormDisabled(false)
      }, 2000);
    }
  }

  function fixMailchimpMessage(msg) {
    if (msg.includes('subscribed')) {
      return `${getValues().EMAIL} is already subscribed.`
    }
    return msg;
  }

  useEffect(() => {
    setFormDisabled(formState.isSubmitting || mailchimpResult.result === "success")
  }, [formState.isSubmitting, mailchimpResult])

  return (
    <div className="w-full sm:w-112 text-center bg-white mx-auto rounded-lg pt-8 sm:shadow-lg">
      <div className="px-8">
        <p className="text-2xl font-semibold text-center text-gray-800">Be an Insider</p>
        <p className="text-lg">Subscribe to get exclusive pricing and an inside look into the book's development.</p>
      </div>
      <form className="flex-col px-8 pt-6 pb-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 mb-3 text-gray-700 leading-tight focus:border-indigo-500"
            name="NAME"
            placeholder="Name"
            type="text"
            ref={register({ required: "Name is required" })}
            disabled={formDisabled}
          />
          {errors.name && <p className="text-red-500 text-xs italic">{ errors.name.message }</p>}
        </div>
        <div className="mb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:border-indigo-500"
            name="EMAIL"
            placeholder="Email"
            type="email"
            ref={register({ required: "Email is required" })}
            disabled={formDisabled}
          />
          {errors.email && <p className="text-red-500 text-xs italic">{ errors.email.message }</p>}
        </div>
        <div className="flex flex-col items-center justify-center">
          { 
            mailchimpResult.result === "error" && 
            <p className="text-red-500 text-sm italic -mt-3 mb-3">{ mailchimpResult.msg }</p>
          }
          { 
            mailchimpResult.result === "success" &&
            <p className="text-green-500 text-sm italic -mt-3 mb-3">{ mailchimpResult.msg }</p>
          }
          <button
            className = {
              `bg-teal-400 ${false ? "hover:bg-blue-700" : "cursor-not-allowed"} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`
            }
            type="submit"
            disabled={formDisabled}
          >{mailchimpResult.result === "success" ? "👍🏽" : "Subscribe"}</button>
        </div>
      </form>
    </div>
  )
}

export default EmailForm